/* ColumnDropdown Style */
.column-checkbox {
  max-height: 300px;
  overflow-y: auto;
}
.column-checkbox .ant-checkbox-wrapper {
  margin-inline-start: 0 !important;
  display: flex !important;
  padding-block: 5px !important;
}
.column-checkbox .ant-checkbox-group {
  display: block !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #1db3b3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #1db3b3;
}

body,
html,
#root {
  height: 100%;
  margin: 0;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.ant-layout {
  height: 100%;
}

/* Utitlities Classes */

.mt-80 {
  margin-top: 80px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.fs-36 {
  font-size: 36px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.lh-24 {
  line-height: 24px;
}

.font-poppins {
  font-family: "Poppins", sans-serif;
}

.font-dark-theme {
  color: #101828;
}

.font-light-theme {
  color: #475467;
}

.font-theme-green {
  color: #1db3b3;
}

.font-dark-theme:hover {
  color: #101828;
}

.font-light-theme:hover {
  color: #475467;
}

.font-theme-green:hover {
  color: #1db3b3;
}

/* Custom Classes */

.form-input-field {
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #667085;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 44px;
}

.theme-button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #1db3b3;
  background: #1db3b3;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: 44px;
  font-family: "Inter";
}

:where(.css-dev-only-do-not-override-9dgt1i).ant-form-item
  .ant-form-item-explain-error {
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 6px;
}

:where(
    .css-dev-only-do-not-override-9dgt1i
  ).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  border-color: #475467;
}

.description-col {
  width: 360;
  white-space: nowrap !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal !important;
  cursor: pointer;
}
.tooltip-text {
  max-height: 100px;
  overflow-y: auto;
  padding-right: 8px;
  white-space: normal;
}
/* Media querries */

@media screen and (max-width: 1370px) {
  .mt-80 {
    margin-top: 40px;
  }
}

.page-wrapper .page-found-wrapper {
  height: 70vh;
}
.page-wrapper .page-found-wrapper.construction {
  height: 70vh;
}
.page-wrapper .page-found-wrapper .page-found-heading h2 {
  font-size: 150px;
}
.page-wrapper .page-found-wrapper .page-found-heading h2.under {
  font-size: 55px;
}
.page-wrapper .page-found-wrapper p {
  padding-top: 15px;
}
.page-wrapper .page-found-wrapper a {
  padding: 11px 22px;
  outline: none;
  border: none;
  border-radius: 4px;
  margin-top: 25px;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .page-wrapper {
    padding: 0 12px;
  }
  .page-wrapper .page-found-wrapper .page-found-heading h2 {
    font-size: 100px;
  }
  .page-wrapper .page-found-wrapper .page-found-heading h2.under {
    font-size: 30px;
  }
  .page-wrapper .page-found-wrapper p {
    font-size: 14px;
  }
  .page-wrapper .page-found-wrapper a {
    padding: 8px 18px;
    font-size: 16px;
  }
}
